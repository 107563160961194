import React from 'react';
import './App.css';
import {SurveyPage} from "./SurveyPage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {PrivacyPolicyPage} from "./PrivacyPolicyPage";
import {TermsOfUsePage} from "./TermsOfUsePage";
import {DeleteAccountPage} from "./DeleteAccountPage";
import {MainPage} from "./MainPage";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<MainPage/>}/>
				<Route path="/survey" element={<SurveyPage/>}/>
				<Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
				<Route path="/terms-of-use" element={<TermsOfUsePage/>}/>
				<Route path="/delete-account" element={<DeleteAccountPage/>}/>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
