import React from "react";

export function DeleteAccountPage() {
	return (
		<div className="App">
			<header className="content">
				<h2>Delete Linguome Account</h2>
				<p>You can delete you account by sending an email to alex@linguo.me</p>
				<p>Alternatively, you can login in the app, go to "Profile" and press "Delete account"</p>
			</header>
		</div>
	)
}
