import React from "react";
import {Link} from "react-router-dom";

export function MainPage() {
	return (
		<div className="App">
			<header className="App-header">
				<h2>Linguome</h2>
				<h4>Learn languages by reading books</h4>
				<Link to={'/survey'}>Survey</Link>
				<div style={{display: "flex", flexDirection: "row", justifyContent: "center", gap: "20px"}}>
					<Link to={'/privacy-policy'}>Privacy policy</Link>
					<Link to={'/terms-of-use'}>Terms of Use</Link>
					<Link to={'/delete-account'}>Delete account</Link>
				</div>
			</header>
		</div>
	)
}
