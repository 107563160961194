import React from "react";

export function PrivacyPolicyPage() {
	return (
		<div className="App">
			<header className="content">
				<h2>Linguome Privacy Policy</h2>
				<p>Effective date: April 15, 2024</p>
				<p>Aleksandr Pavlushkin (“we,” “us,” or “our”) respects the privacy of its users (“you” or “your”). This
					Privacy Policy describes the information we collect, how that information may be used, with whom it
					may be shared, and your choices about such uses and disclosures when you use the Linguome mobile
					application, website, or any associated software that we provide for you (collectively, the
					“Service”). Unless otherwise defined, terms used in this Privacy Policy have the same meanings as in
					our Terms of Service.</p>
				<p>We encourage you to take a few minutes to read this Privacy Policy and understand it. By using our
					Service, you agree to the collection and use of information in accordance with this policy. If you
					have any question about our privacy practices, please refer to the end of this Privacy Policy for
					information on how to contact us.</p>
				<h3>What Information We Collect about You</h3>
				<p>We may collect information that can identify you ("Personal Information") and other information that
					does not identify you, as described below.</p>
				<h4>Information You Provide</h4>
				<p>When using our Service, you will be asked to provide information including, but not limited to, your
					name, email address, phone number, contact list. We will also ask you to authorize the access to
					your certain Google or Apple account information. If you chat with other users of
					our Service, you provide us with the content of your chats. If you contact us, you provide us with
					the content of that communication, and if you transact business with us, you provide us with the
					related financial information.</p>
				<h4>Log Data</h4>
				<p>We collect information that your browser or device sends whenever you visit our Service (“Log Data”).
					This Log Data may include information such as your computer’s Internet Protocol (“IP”) address,
					browser type, browser version, the pages of our Service that you visit, the time and date of your
					visit, the time spent on those pages, the type of mobile device and operating system you use, your
					mobile device’s unique device ID, the type of mobile Internet browser you use, your location
					information, and other statistics.</p>
				<p>In addition, we may use third-party services such as Google Analytics that collect, monitor and
					analyze this type of information in order to increase our Service’s functionality. These third-party
					service providers have their own privacy policies addressing how they use such information.</p>
				<p>We collect this information through our website, mobile application, or third-party partners by using
					the following technologies:</p>
				<h4>Cookies and Pixel Tags</h4>
				<p>When you visit our Service, we may use cookies to facilitate access to our Service and to personalize
					your experience. A cookie is a bit of information that a website sends to your web browser to help
					the site remember information about you and your preferences. We also may embed pixel tags (also
					called web beacons or clear GIFs) on web pages, ads, and emails to measure the popularity of our
					features and services. Pixel tags are invisible graphics used to access cookies and track user
					activities (such as how many times a page is viewed).</p>
				<h4>Mobile Device IDs</h4>
				<p>If you use the Service through our mobile applications, we use mobile device IDs (i.e., the unique
					identifier assigned to a device by the manufacturer), or Advertising IDs (for iOS 6 and later),
					instead of cookies, to recognize you. We do this to store your preferences and track your use of our
					app. Data analytics companies also use device IDs to track information about the application
					usage.</p>
				<p>We may allow service providers, advertising companies and ad networks, and other third parties to
					display ads on our Service. These companies may use tracking technologies such as cookies (sometimes
					called DoubleClick cookies), web beacons, or mobile device IDs to collect information about users
					who view or interact with their ads.</p>

				<h3>How We Use the Information We Collect</h3>
				<p>We may use the information that we collect about you to</p>
				<ul>
					<li>provide and improve our products and services, and manage our business;</li>
					<li>manage your account, provide you with customer support, and respond to your
						requests/inquiries;
					</li>
					<li>perform research and analysis about your use of, or interest in, our or others’ products,
						services, or content;
					</li>
					<li>communicate with you by email, telephone, push notification, and/or other means about products
						or services that may be of interest to you either from us or other third parties;
					</li>
					<li>develop, display, and track content and advertising tailored to your interests on our Service
						and other sites, including providing our ads to you when you visit other sites;
					</li>
					<li>website or mobile application analytics;</li>
					<li>verify your eligibility;</li>
					<li>enforce or exercise any rights in our Terms of Service; and</li>
					<li>perform functions or services as otherwise described to you at the time of collection.</li>
				</ul>
				<p>In all circumstances, we may perform these functions directly or use a third-party vendor to perform
					these functions on our behalf who will be obligated to use your Personal Information only to perform
					services for us.</p>
				<h3>With Whom We Share Your Information</h3>
				<p>We will not share your Personal Information with others except as indicated in this Privacy Policy or
					when we inform you and give you an opportunity to opt out of having your Personal Information
					shared. We may share your information with:</p>
				<h4>Other Users of Our Service</h4>
				<p>We may share your Personal Information with other users of our Service in order to provide the Service. For example, if your friends in the Service visit your profile page, they may see your name and some of your activities, and vice versa, to get value from the Service.</p>
				<h4>Service Providers</h4>
				<p>We may employ third-party companies and individuals to facilitate our Service, to provide the Service on our behalf, to perform Service-related services, and/or to assist us in analyzing how our Service is used. These third parties have access to your information only to perform specific tasks on our behalf and are obligated not to disclose Personal Information for any other purpose.</p>
				<h4>Advertising Partners</h4>
				<p>If we advertise, we or third-party (behavioral) advertising partners may use certain data collected on our Service to show you our ads on other sites or applications.</p>
				<p>We also may disclose your Personal Information in the following situations:</p>
				<h4>Compliance with Laws</h4>
				<p>We do so by law or subpoena or if we believe that such action is necessary to comply with the law and the reasonable requests of law enforcement or to protect the security or integrity of our Service.</p>
				<h4>Enforcing Proper Use</h4>
				<p>We do so when we believe disclosure is appropriate in connection with efforts to investigate, prevent, or take other action regarding illegal activity, suspected fraud, or other wrongdoing; to protect and defend the rights, property or safety of our company, our users, our employees, or others; or to enforce our Terms of Service or other agreements or policies.</p>
				<h4>Business Transaction</h4>
				<p>If Linguome is involved in a merger, acquisition, asset sale, or in the unlikely event of bankruptcy, your Personal Information may be transferred as a business asset. In such cases, we will provide notice before your Personal Information is transferred and/or becomes subject to a different Privacy Policy.</p>
				<p>We also may share aggregated, non-personal information, or personal information in hashed, non-human readable form, with third parties, including advisors, advertisers, and investors, for the purpose of conducting general business analysis or other business purposes.</p>

				<h3>How We Protect Your Personal Information</h3>
				<p>The security of your Personal Information is important to us, and we strive to implement and maintain reasonable, commercially acceptable security procedures and practices appropriate to the nature of the information we store, in order to protect it from unauthorized access, destruction, use, modification, or disclosure. However, please be aware that no method of transmission over the internet, or method of electronic storage is 100% secure, and you should not expect the absolute security of your Personal Information.</p>
				<p>Users should also take care with how they handle and disclose their personal information and should avoid sending personal information through insecure channels such as email. Please refer to the Federal Trade Commission’s website for information about how to protect yourself against identity theft.</p>

				<h3>Where Information Is Processed</h3>
				<p>Our information processing facilities are based in the United States. If you are located outside the United States and choose to provide information for us, please note that we transfer the information, including Personal Information, to the United States and process it there.</p>
				<p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that your information, including Personal Information, may be transferred to—and maintained on—computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>

				<h3>Your Choices About Collection and Use</h3>
				<p>If you have a Linguome account, you have the ability to review and update your Personal Information
					within the Service. You can delete the Content you have posted by either following the instructions
					in the Service or submitting requests via email to alex@linguo.me. Content you delete will be hidden
					or anonymized from other users. However, we may maintain a copy of such Content in our records.
					Please be aware that while information can be hidden or anonymized, it may not be possible to
					completely remove the information (such as when the Content is jointly created by multiple users)
					and that there still may be unwanted effects (such as when others had already seen the Content). So
					we encourage you to exercise discretion and caution with respect to your Personal Information.</p>
				<p>If you wish to back-up the whole or part of your Content, you will need to do so yourself. We do not
					undertake the obligation of backing-up user content.</p>
				<p>You also have control over the collection and use of your Personal Information in the following areas
					of our Service:</p>
				<h4>Push Notifications</h4>
				<p>We may send push notifications or alerts to your device. You can deactivate these notifications at
					any time by changing the notification settings on your device.</p>
				<h4>Cookies</h4>
				<p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. The
					Help feature on most browsers provide information on how to accept cookies, disable cookies, or to
					notify you when receiving a new cookie. If you do not accept cookies, you may not be able to use
					some features of our Service and we recommend that you leave them turned on.</p>
				<p>To opt-out of cookies that may be set by the third-party advertising services who serve ads on our
					Service, please visit the Network Advertising Initiative’s choise page. Please be aware that opting
					out will not decrease the number of ads you see, if any, on our Service.</p>
				<p>To opt-out of cookies that may be set by third-party data or advertising partners who display our
					ads, please go to Digital Advertising Alliance’s Choice page.</p>
				<h4>Mobile Device IDs</h4>
				<p>Unlike cookies, mobile device IDs cannot be deleted, but if you are using iPhone with iOS 6 or later,
					you can reset your Advertising ID in “Settings” on your iPhone.</p>
				<h4>Communications</h4>
				<p>We may send you newsletters, marketing, or promotional materials and other information that may be of
					interest to you through email or our mobile application. You may opt out of receiving any, or all,
					of these communications from us by following the unsubscribe link or instructions provided in any
					email we sent or by contacting us via alex@linguo.me</p>

				<h3>Links to Other Sites</h3>
				<p>Our Service may contain links to other sites that are not operated by us. If you click on a
					third-party link, you will be directed to that third-party’s site. We have no control over, and
					assume no responsibility for the content, privacy policies, or practices of any third-party sites or
					services. So, we strongly advise you to review the Privacy Policy of every site you visit.</p>
				<h3>Children’s Privacy</h3>
				<p>Only persons aged 13 or older have permission to access our Service. Our Service does not address
					anyone under the age of 13 (“Children”).</p>
				<p>We do not knowingly collect personally identifiable information from children under 13. If you are a
					parent or guardian and you learn that your Children have provided us with Personal Information,
					please contact us via email at alex@linguo.me. If we become aware that we have collected Personal
					Information from a children under age 13 without verification of parental consent, we take steps to
					remove that information from our servers.</p>
				<p>To protect persons aged between 13 and 18 (“Minors”), we may put special safeguards in place,
					recognizing this may provide Minors with a more limited experience on our Service. Minors can have
					their Content hidden (or anonymized) from other users by either following the instructions in our
					Service or submitting requests via email to alex@linguo.me.</p>
				<h3>Changes to This Privacy Policy</h3>
				<p>This Privacy Policy is effective as of the Effective Date set forth above and will remain in effect
					except with respect to any changes in its provisions in the future, which will be in effect
					immediately after being posted on this page.</p>
				<p>We reserve the right to update or change our Privacy Policy at any time, so please be sure to check
					this Privacy Policy periodically. Your continued use of the Service after we post any modifications
					to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your
					consent to abide and be bound by the modified Privacy Policy.</p>
				<p>If we make any material changes to this Privacy Policy, we will notify you either by placing a
					prominent notice on this page, or through our mobile application.</p>
				<h3>Contact Us</h3>
				<p>If you have any question about this Privacy Policy, please contact us via email at alex@linguo.me</p>
			</header>
		</div>
	)
}
