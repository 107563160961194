import React from "react";

export function TermsOfUsePage() {
	return (
		<div className="App">
			<header className="content">
				<h2>Terms Of Use</h2>
				<p>Effective date: April 15, 2024</p>
				<p>Welcome to Linguome! These Terms of Service (the “Terms” or “Agreement”) are a legal contract between
					you and Aleksandr Pavlushkin (the “Company,” “we,” “us,” or “our”), the owner and operator of the
					Linguome mobile application, website, and any associated service we provide to you (collectively, the
					“Service”). These Terms apply to all visitors and users, whether or not they create Linguome
					accounts. Your access to and use of the Service is conditioned upon your acceptance of and
					compliance with these Terms. If you wish to create a Linguome account and/or make use of the
					Service, please take a few minute to read and understand these Terms.</p>
				<p>You should also read our <a href={'privacy-policy'}>Privacy Policy</a>, which is incorporated by
					reference into these Terms and available in the Service. If you do not accept and agree to be bound
					by all of this Agreement, including our Privacy Policy, please do not use the Service. If you have
					any question regarding these Terms, please refer to the end of this document for information on how
					to contact us.</p>
				<h3>Your Account</h3>
				<p>In order to use the Service, you may sign in using your Google or Apple account. If you do so, you
					authorize us to access and use certain Google or Apple account information, such as your friend
					list. For more information regarding the information we collect from you and how we use it, please
					consult our Privacy Policy.</p>
				<h4>Eligibility</h4>
				<p>You must be aged 13 or above to access and use the Service. By accessing and using the Service, you
					represent and warrant that you have the right, authority, and capacity to enter into all provisions
					of the Terms. When you create an account with us, you guarantee that the information you provide us
					is accurate, complete, and current at all times. The company reserves the right to conduct any
					criminal background check or other screenings at any time and using available public records.</p>
				<p>You may not use as a username the name of another person or entity or that is not lawfully available
					for use, or a name or trademark that is subject to any rights of another person or entity other than
					you, without appropriate authorization. You may not use as a username any name that is offensive,
					vulgar, or obscene.</p>
				<p>The Services may not be available in all countries, and we may block access to the Services from
					certain countries based on your device’s geolocation information. We may add to or remove the areas
					in which the Services are available at any time, without notice to you.</p>
				<h4>Security</h4>
				<p>You are responsible for maintaining the confidentiality of your account and password, including, but
					not limited to, the restriction of access to your computer and/or account. You are solely
					responsibility for any and all activities or actions that occur under your account and/or password,
					whether your password is with our Service or a third-party service. You agree to notify us
					immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
				<h4>Use</h4>
				<p>Each account in the Service is for the exclusive use and belongs solely to the owner of the account.
					Users may not transfer or lend their accounts to any third-party nor may their accounts be inherited
					by any third-party.</p>
				<p>The Service is for personal use only. Users may not use the Service or any content contained in the
					Service (including, but not limited to, content of other users, designs, text, graphics, images,
					video, information, logos, software, audio files, and computer kdb) in connection with any
					commercial endeavors, such as (i) advertising or soliciting any user to buy or sell any products or
					services not offered by us; or (ii) soliciting others to attend parties or other social functions,
					or networking, for commercial purposes. Users of the Service may not use any information obtained
					from the Service to contact, advertise to, solicit, or sell to any other user without his or her
					prior explicit consent. Organizations, companies, and/or businesses may not use the Service for any
					purpose except with Aleksandr Pavlushkin’s express consent, which the Company may provide or deny in its
					sole discretion.</p>
				<h4>Communications</h4>
				<p>By creating an account on our service, you agree to subscribe to newsletters, marketing or
					promotional materials, and other information we may send. However, you may opt out of receiving any,
					or all, of these communications from us by following the unsubscribe link or instructions provided
					in any email we send or by contacting us via alex@linguo.me.</p>
				<h4>Termination</h4>
				<p>If, at any time, you wish to terminate your account, you may simply discontinue using the Service. We
					reserve the right to refuse service, suspend or terminate accounts, remove or edit content, or
					cancel orders at any time without notice if we believe that you have breached these Terms, or for
					any other reason, with or without cause, in our sole discretion. Upon such termination or
					suspension, you will not be entitled to any refund of unused fees for your purchases (including
					in-app purchases). The Company is not required to disclose, and may be prohibited by law from
					disclosing, the reason for the termination or suspension of your account. After your account is
					terminated for any reason, all provisions of the Terms (such as ownership provisions, warranty
					disclaimers, indemnity, and limitations of liability) survive such termination and continue in full
					force and effect, except for the provisions that by their nature expire or are fully satisfied.</p>
				<h3>Prohibited Activities</h3>
				<p>You are responsible for complying with the laws and regulations of the United States, where our
					information processing facilities are based in, and the territory from which you access or use the
					Service. You must not use the Service in any way that violates applicable
					local/state/federal/international laws, regulations, or other government requirements.</p>
				<p>Following partially lists the types of actions that you may not engage in with respect to the
					Service. You will not:</p>
				<ul>
					<li>use the Service in an illegal manner or to commit an illegal act;</li>
					<li>access the Service in a jurisdiction in which it is illegal or unauthorized;</li>
					<li>impersonate any person or entity, including, but not limited to, a user, supplier, or any person
						associated with Linguome;
					</li>
					<li>invade the privacy or violate any personal or proprietary right (including intellectual property
						rights) of any person or entity;
					</li>
					<li>offend or harass any person or entity;</li>
					<li>ask or use users to conceal the identity, source, or destination of any illegally gained money
						or products;
					</li>
					<li>solicit money (or other economic benefits) from other users;</li>
					<li>promote, solicit, or participate in commercial activities (such as multi-level marketing and
						pyramid schemes) not authorized by the Company;
					</li>
					<li>alter the opinions of others for any purpose violating these Terms;</li>
					<li>post “Content” that is prohibited by this Agreement (defined under section Content Posted by You
						below);
					</li>
					<li>exchange the right to use Content for money or other economic benefits without Company’s
						authorization;
					</li>
					<li>express or imply that any statements you make are endorsed by the Company or Service without our
						specific prior written consent;
					</li>
					<li>use your “Purchase” in a manner that is not authorized by the Company (defined under section
						Purchases below);
					</li>
					<li>use any robot, spider, site search/retrieval application, or other manual or automatic device or
						process to retrieve, index, “data mine,” or in any way reproduce or circumvent the navigational
						structure or presentation of the Service or its content;
					</li>
					<li>collect usernames and/or email addresses of users by electronic or other means for the purpose
						of sending unsolicited emails or unauthorized framing of or linking to the Service;
					</li>
					<li>interfere with or disrupt the Service or the servers or networks connected to the Service;</li>
					<li>email or otherwise transmit any material that contains software viruses or any other computer
						kdb, files, or programs designed to interrupt, destroy or limit the functionality of any
						computer software or hardware or telecommunications equipment;
					</li>
					<li>forge headers or otherwise manipulate identifiers in order to disguise the origin of any
						information transmitted to or through the Service (either directly or indirectly through use fo
						third-party software);
					</li>
					<li>“frame” or “mirror” any part of the Service, without the Company’s prior written
						authorization;
					</li>
					<li>use meta tags or kdb or other devices containing any reference to the Company or the Service (or
						any our trademark, trade name, service mark, logo, slogan, or any or any confusingly similar
						marks) to direct any person to any other website for any purpose;
					</li>
					<li>modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile, or otherwise
						disassemble any portion of the Service any software used on or for the Service, or cause others
						to do so; and
					</li>
					<li>post, use, transmit, or distribute, either directly or indirectly (e.g. screen scrape), in any
						manner or media any content or information obtained from the Service other than solely in
						connection with your use of the Service in accordance with these Terms.
					</li>
				</ul>
				<p>We reserve the right, without prior notice and in our sole discretion, to decide whether any use
					violates these Terms for any of the above reasons or for any other reason, and if so, to remove
					offending Content and to suspend/terminate your access to the Service.</p>
				<p>You agree that we may access, preserve, and disclose your account information and Content if required
					to do so by law or in a good faith belief that such access, preservation, or disclosure is
					reasonably necessary, such as to: (i) comply with legal process; (ii) enforce this Agreement; (iii)
					respond to claims that any content violates the rights of third-parties; (iv) respond to your
					requests for customer service or allow you to use the Service in the future; or (v) protect the
					rights, property, or personal safety of the Company or any other person.</p>
				<h3>Content Posted by You</h3>
				<p>Our Service allows users to post, link to, upload, share or transmit to other users, comment, and
					otherwise make available (hereinafter, “post”) certain information, text, graphics, videos, or other
					material (“Content”). You are responsible for the Content that you post on or through the Service,
					including its legality, reliability, and appropriateness. By posting Content on or through the
					Service, you represent and warrant that: (i) the Content is yours (i.e., you own it) and/or you have
					the right to use it and the right to grant us the rights and license as provided in these Terms; and
					(ii) the posting of your Content on or through the Service does not violate the privacy rights,
					publicity rights, copyrights, contract rights, or any other rights of any person or entity. We
					reserve the right to terminate the account of anyone found to be infringing on a copyright.</p>
				<p>You retain any and all of your rights to any Content you post and you are responsible for protecting
					those rights. We take no responsibility and assume no liability for Content you or any third-party
					posts on or through the Service. However, by posting Content using the Service you automatically
					grant us, our affiliates, licensees, and successors, an irrevocable, perpetual, non-exclusive,
					transferable, sub-licensable, fully paid-up, worldwide right and license to (i) use, copy, store,
					perform, display, reproduce, record, play, adapt, modify and distribute the Content, (ii) prepare
					derivative works of the Content or incorporate the Content into other works, and (iii) grant and
					authorize sublicenses of the foregoing in any media now known or hereafter created. You represent
					and warrant that any posting and use of your Content by the Company will not infringe or violate the
					rights of any third party. You also agree that any Content you place on the Service may be viewed by
					other users and may be viewed by any person visiting or participating in the Service.</p>
				<p>Following is a partial list of the kind of Content that is prohibited on or through the Service. You
					may not post Content that:</p>
				<ul>
					<li>promotes racism, bigotry, hatred, or physical harm of any kind against any individual or
						group;
					</li>
					<li>advocates harassment or intimidation of another person;</li>
					<li>requests money from, or is intended to otherwise defraud, other users of the Service;</li>
					<li>involves the transmission of “junk mail,” “chain letters,” or unsolicited mass mailing or
						“spamming” (or “spimming”, “phishing”, “trolling,” or similar activities);
					</li>
					<li>promotes information that is false or misleading, or promotes illegal activities or conduct that
						is defamatory, libelous, or otherwise objectionable;
					</li>
					<li>promotes an illegal or unauthorized copy of another person’s copyrighted work, such as providing
						pirated computer programs or links to them, providing information to circumvent
						manufacture-installed copy-protect devices, or providing pirated images, audio or video, or
						links to pirated images, audio or video files;
					</li>
					<li>contains video, audio photographs, or images of another person without his or her permission (or
						in the case of a minor, permission of the minor’s legal guardian);
					</li>
					<li>contains restricted or password-only access pages, or hidden pages or images (i.e., those not
						linked to or from another accessible page);
					</li>
					<li>provides material that exploits people in a sexual, violent, or other illegal manner, or
						solicits personal information from anyone under the age of 18;
					</li>
					<li>provides instructional information about illegal activities such as making or buying illegal
						weapons or drugs, violating someone’s privacy, or providing, disseminating, or creating computer
						viruses;
					</li>
					<li>contains viruses, time bombs, trojan horses, cancelbots, worms, or other harmful or disruptive
						kdbs, components, or devices;
					</li>
					<li>impersonates, or otherwise misrepresents affiliation, connection, or association with, any
						person or entity;
					</li>
					<li>provides information or data you do not have a right to make available under law or under
						contractual or fiduciary relationships (such as inside information, proprietary and confidential
						information);
					</li>
					<li>disrupts the normal flow of dialogue, causes a screen to “scroll” faster than other users are
						able to type, or otherwise negatively affects other users’ ability to engage in real time
						exchanges;
					</li>
					<li>solicits passwords or personal identifying information for commercial or unlawful purposes from
						other users, or disseminates another person’s personal information without his or her
						permission;
					</li>
					<li>publicizes or promotes commercial activities and/or sales without our prior written consent such
						as contests, sweepstakes, barter, advertising, and pyramid schemes;
					</li>
					<li>depicts cruelty to animals; and</li>
					<li>provide anything contrary to our public image, goodwill, or reputation.</li>
				</ul>
				<p>Linguome has the right but not the obligation to monitor and edit all Content provided by users.
					In addition, Content found on or through this Service are the property of the Company or used with
					permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said
					Content, whether in whole or in part, for commercial purposes or for personal gain, without express
					advance written permission from us.</p>
				<h3>Proprietary Rights</h3>
				<p>The Service is protected by copyright, trademark, patent, and other laws of both the United States
					and foreign countries. We own and retain all proprietary rights in the Service, and in all content,
					trademarks, trade names, service marks, and other intellectual property rights related thereto. The
					Service contains the copyrighted material, trademarks, patented invention, and other proprietary
					information of the Company and its licensors. You agree to not copy, modify, transmit, create any
					derivative works from, make use of, or reproduce in any way any copyrighted material, trademark,
					patented invention, trade name, service mark, or other intellectual property or proprietary
					information accessible through the Service, without first obtaining the prior written consent of the
					Company or, if such property is not owned by us, the owner of such intellectual property or
					proprietary rights. You agree to not remove, obscure, or otherwise alter any proprietary notices
					appearing on any content, including copyright, trademark, patent, and other intellectual property
					notices.</p>
				<h3>Your Interactions with Other Users</h3>
				<p>You are solely responsible for your interactions with other users. You understand that the company
					currently does not inquire into the backgrounds of all of its users or attempt to verify the
					statements of its users. The company makes no representations or warranties as to the conduct of
					users or their compatibility with any current or future users. The company reserves the right to
					conduct any background check or other screenings, at any time and using available public
					records.</p>
				<p>You agree that, in no event shall the Company, its affiliates, or its partners be liable (directly or
					indirectly) for any losses or damages whatsoever, whether direct, indirect, general, special,
					compensatory, consequential, and/or incidental, arising out of or relating to the conduct of you or
					anyone else in connection with the use of the Service and/or any other damages resulting from
					communications or interactions with other users or persons through the Service. You agree to take
					all necessary precautions in all interactions with other users, particularly if you decide to
					communicate off the Service or meet in person, or if you decide to send money to another user. You
					understand that the Company makes no guarantees, either express or implied, regarding your ultimate
					compatibility with individuals you interact with through the Service.</p>
				<h3>Purchases</h3>
				<p>If you wish to purchase any product or service made available through the Service (“Purchase”), you
					may be asked to supply certain information relevant to your Purchase including, without limitation,
					your account with the mobile platform (e.g., iOS, Android, etc.) for in-app purchase (“IAP Account”)
					or your credit card number, the expiration date of your credit card, your billing address, and your
					shipping information. You will be charged for your Purchase in accordance with the terms disclosed
					to you at the time of purchase and/or the general terms that apply to the chosen payment method on
					your platform.</p>
				<p>You represent and warrant that: (i) you have the legal right to use the credit card(s) or other
					payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is
					true, correct, and complete. The service may use third-party services for the purpose of
					facilitating payment and the completion of Purchases. By submitting your information, you grant us
					the right to provide the information to these third-parties subject to our Privacy Policy.</p>
				<p>We reserve the right to refuse or cancel your order at any time for reasons including but not limited
					to: product or service availability, errors in the description or price of the product or service,
					error in your order, fraud or an unauthorized or illegal transaction is suspected, or other reasons.
					After delivering the ordered products or services, we remain the holder of all intellectual property
					rights as well as all other rights, and such rights shall not be transferred to our users.</p>
				<h3>Customer Service and Feedback</h3>
				<p>We provide assistance and guidance through our customer-care representatives that can be reached in
					our Service or via email at alex@linguo.me. When communicating with our customer-care
					representatives, you agree to not be abusive, obscene, profane, offensive, sexist, threatening,
					harassing, racially offensive, or to not otherwise behave inappropriately. If we feel that your
					behavior towards any of our customer-care representatives or other employees is at any time
					inappropriate, we reserve the right to immediately terminate your account.</p>
				<p>If you send or transmit any communications, comments, questions, suggestions, or related materials
					regarding the Company or the Services, whether by email or otherwise (collectively, “Feedback,”
					which is a part of your Content), you understand and agree that the Company is not obligated to use,
					display, reproduce, or distribute any ideas, know-how, concepts, or techniques contained in the
					Feedback, and you have no right to compel such use, display, reproduction, or distribution.</p>
				<h3>Copyright Policy</h3>
				<p>We respect the intellectual property rights of others. It is our policy to respond to any claim that
					Content posted on the Service infringes on the copyright or other intellectual property rights
					(“Infringement”) of any person or entity.</p>
				<p>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted
					work has been copied in a way that constitutes copyright infringement, please submit via email a
					notification pursuant to the Digital Millennium Copyright Act (DMCA) to our Copyright Agent at
					alex@linguo.me, with the subject line: “Copyright Infringement,” and include in your
					submission a detailed description of the alleged Infringement as detailed below (see 17 U.S.C
					512(c)(3) for further detail):</p>
				<ul>
					<li>an electronic or physical signature of the person authorized to act on behalf of the owner of
						the copyright’s interest;
					</li>
					<li>a description of the copyrighted work that you claim has been infringed, including the URL
						(i.e., web page address) of the location where the copyrighted work exists or a copy of the
						copyrighted work;
					</li>
					<li>identification of the URL or other specific location on the Service where the material that you
						claim is infringing is located;
					</li>
					<li>your address, telephone number, and email address;</li>
					<li>a statement by you that you have a good faith belief that the disputed use is not authorized by
						the copyright owner, its agent, or the law; and
					</li>
					<li>a statement by you, made under penalty of perjury, that the above information in your notice is
						accurate and that you are the copyright owner or authorized to act on the copyright owner’s
						behalf.
					</li>
				</ul>
				<p>We will notify you that we have removed or disabled access to copyright-protected material that you provided, if such removal is pursuant to a valid DMCA take-down notice that we have received. We will also terminate the accounts of repeat infringers. Please note that you may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through the Service on your copyright.</p>
				<h3>Disclaimer of Warranties</h3>
				<p>You acknowledge and agree that neither the Company nor its affiliates and third party partners are responsible for and shall not have any liability, directly or indirectly, for any loss or damage, including personal injury or death, as a result of or alleged to be the result of (i) any incorrect or inaccurate Content posted in the Service, whether caused by users or any of the equipment or programming associated with or utilized in the Service; (ii) the timeliness, deletion or removal, incorrect delivery of or failure to store any Content, communications, or personalization settings; (iii) the conduct, whether online or offline, of any user; (iv) any error, omission, defect, interruption, deletion, alteration, delay in operation or transmission, theft or destruction of or unauthorized access to any user or user communications; or (v) any problems, failure, or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of email, or other players on account of technical problems or traffic congestion on the Internet or at any website or combination thereof, including injury or damage to users or to any other person’s computer or device related to or resulting from participating or downloading materials in connection with the Internet and/or in connection with the Service.</p>
				<p>To the maximum extent allowed by applicable law, the company provides the Service on an “AS IS” and “AS AVAILABLE” basis and grants no warranties of any kind, whether express, implied, statutory, or otherwise with respect to the Service (including all content contained therein), including but not limit to any implied warranties of satisfactory quality, merchantability, fitness for a particular purpose, or non-infringement of third-party rights. The Company does not (i) represent or warrant that the service will be uninterrupted, error free, secure, or that any defects or errors in the service will be corrected; (ii) guarantee the accuracy, completeness, or usefulness of any content provided through the service; or (iii) adopt, endorse or accept responsibility for the accuracy or reliability of any opinion, advice, or statement made by any party that appears in the Service. Under no circumstances will the Company or its affiliates be responsible or liable for any loss or damage resulting from your reliance on information or other Content posted in the Service or transmitted to or by any user.</p>
				<h3>Limitation of Liability</h3>
				<p>To the fullest extent allowed by applicable law, in no event will the Company, its affiliates, business partners, licensors, or service providers be liable to you or any third-person for any indirect, reliance, consequential, exemplary, incidental, special, or punitive damages, including, without limitation, loss of profits, loss of goodwill, damages for loss, corruption or breaches of data or programs, service interruptions, and procurement of substitute services, even if the Company has been advised of the possibility of such damages. Notwithstanding anything to the contrary contained herein, the Company’s liability to you for any cause whatsoever, and regardless of the form of the action, will at all times be limited to the amount paid, if any, by you to the Company for the Service while you have an account. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Service or the provisions of these Terms must be filed within one year after such claim or cause of action arose or be forever barred.</p>
				<p>Force Majeure: The Company shall not be in default of any obligation under this Agreement if the failure to perform the obligation is due to any event beyond our control, including, without limitation, significant failure of a portion of the power grid, significant failure of the Internet, natural disaster, war, riot, insurrection, epidemic, strikes or other organized labor action, terrorist activity, or other events of a magnitude or type for which precautions are not generally taken in the industry.</p>
				<h3>Dispute Resolution and Governing Law</h3>
				<p>Many customer concerns can be resolved quickly and to a customer’s satisfaction by contacting our customer-care representatives at alex@linguo.me. So, please contact us first if you have any concern.</p>
				<p>This provision facilitates the prompt and efficient resolution of any dispute that may arise between you and the Company. The exclusive means of resolving dispute or claim arising out of or relating to this Agreement (including any alleged breach thereof) or the Service shall be Binding Arbitration administered by the American Arbitration Association. The one exception to the exclusivity of arbitration is that you have the right to bring an individual claim against the Company in a small-claims court of competent jurisdiction. But whether you choose arbitration or small-claims court, you may not under any circumstances commence or maintain against the Company any class action, class arbitration, or other representative action or proceeding.</p>
				<p>By using the Service in any manner, you agree to the above arbitration agreement. In doing so, you give up your right to go to court to assert or defend any claims between you and the Company (except for matters that may be taken to small-claims court). You also give up your right to participate in a class action or other class proceeding. Your rights will be determined by a neutral arbitrator, not a judge or jury. You are entitled to a fair hearing before the arbitrator. The arbitrator can grant any relief that a court can, but you should note that arbitration proceedings are usually simpler and more streamlined than trials and other judicial proceedings. Decisions by the arbitrator are enforceable in court and may be overturned by a court only for very limited reasons. Any proceeding to enforce this arbitration agreement, including any proceeding to confirm, modify, or vacate an arbitration award, may be commenced in any court of competent jurisdiction. In the event that this arbitration agreement is for any reason held to be unenforceable, any litigation against the Company (except for small-claims court actions) may be commenced only in the federal or state courts located in Sussex County, Delaware. You hereby irrevocably consent to the jurisdiction of those courts for such purposes.</p>
				<p>This Agreement, and any dispute between you and the Company, shall be governed by the laws of the state of Delaware without regard to principles of conflicts of law, provided that this arbitration agreement shall be governed by the Federal Arbitration Act. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. This Agreement, with the Privacy Policy and any specific guidelines or rules that are separately posted for particular services or offers in the Service, constitute the entire agreement between you and the Company regarding the use of Service.</p>
				<h3>Indemnification</h3>
				<p>You agree to indemnify and hold the Company, its subsidiaries, and affiliates, and its and their officers, agents, partners, and employees, harmless from any loss, liability, claim, or demand, including reasonable attorney’s fees, made by any third-party due to or arising out of your breach of or failure to comply with this Agreement (including any breach of your representations and warranties contained herein), any postings or Content you post in the Service, and the violation of any law or regulation by you. The Company reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with the Company in connection therewith.</p>
				<h3>Changes</h3>
				<p>We are constantly updating product and service offerings on the Service. We may experience delays in updating information on the Service and in our advertising on other web sites or services. The information found on the Service may contain errors or inaccuracies and may not be complete or current. Products or services may be mispriced, described inaccurately, or unavailable on the Service and we cannot guarantee the accuracy or completeness of any information found on the Service. We therefore reserve the right to change or update the information and to correct errors, inaccuracies, or omissions at any time without prior notice.</p>
				<p>We also reserve the right at any time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that the Company shall not be liable to you or to any third-party for any modification, suspension, or discontinuance of the Service.</p>
				<p>This Agreement is effective as of the Effective Date set forth above and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page. We reserve the right to modify or replace these Terms at any time, so please be sure to check these Terms periodically. If a revision is material we will notify you either by placing a prominent notice on this page, or through our Service. You may not receive such notices if you violate this Agreement, and what constitutes a material change will be determined at our sole discretion. By continuing to access or use our Service after any revision becomes effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</p>
				<h3>Contact Us</h3>
				<p>If you have any question about these Terms, please contact us via email at alex@linguo.me.</p>
			</header>
		</div>
	)
}
