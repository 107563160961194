import React from "react";

export function SurveyPage() {
	return (
		<div className="App">
			<header className="App-header">
				<h2>linguome</h2>
				<h4>Your language learning buddy</h4>
				<a
					className="App-link"
					href="https://calendly.com/linguome/jtbd-interview-language-learning"
					target="_blank"
					rel="noopener noreferrer"
				>
					Schedule time with me
				</a>
				<div className="text">
					<p>
						Dear Language Learner,
					</p>
					<p>
						My name is Alex, and I am the founder of linguo.me, a platform dedicated to enhancing the
						language
						learning experience. We are currently embarking on an exciting project to better understand the
						needs,
						challenges, and successes of students preparing for language proficiency exams such as the
						IELTS,
						TOEFL,
						CAE, and other similar tests.
					</p>
					<p>
						Whether you are in the midst of your preparation or have recently taken any of these exams, your
						insights could play a vital role in shaping the future of language learning tools and resources.
						To
						this
						end, we would like to invite you to participate in a one-hour, online interview where you can
						share
						your
						experiences, struggles, and achievements.
					</p>
					<p>
						<b>Interview Details</b>:
						<ul>
							<li>Duration: 1 hour</li>
							<li>Location: Online (a link will be provided upon confirmation of your participation)</li>
							<li>Purpose: To gather insights into the language learning journey of students preparing for
								proficiency exams
							</li>
							<li>Benefits: Your participation will not only contribute to the development of more
								effective language learning solutions but also give you early access to new features and
								tools we develop based on the insights gathered.
							</li>
						</ul>
					</p>
					<p>
						Your journey, challenges, and insights are incredibly valuable to us at Linguo.me. By sharing
						your
						experience, you'll be directly contributing to a community of learners striving to achieve their
						language goals.
					</p>
					<p>
						Thank you for considering this invitation. We look forward to the possibility of speaking with
						you
						and
						learning from your experiences.
					</p>
					<p>
						Warm regards,
						Alex
					</p>
				</div>
				<a
					className="App-link"
					href="https://calendly.com/linguome/jtbd-interview-language-learning"
					target="_blank"
					rel="noopener noreferrer"
				>
					Schedule time with me
				</a>
				<div className="footer"/>
			</header>
		</div>
	)
}
